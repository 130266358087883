/* ========================================================================
 * Forms & validation
 * ======================================================================== */

// For getting baseurl
function getBaseURL () {
    
   return location.protocol + "//" + location.hostname + 
      (location.port && ":" + location.port) + "/";
}

// For validating emailaddress
function validateEmail(value) {
    
    var expression = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;   
    var test = expression.test(value) ? true : false ;
    return test;
}

// For checking inputfields
function checkFields(element) {
    
    var value = $(element).val();
    var elementType = $(element).attr('type');
    
    if(!value && $(element).prop('required')) {
        $(element).addClass('error');
    } else {
        $(element).removeClass('error');
    }
    
    switch(elementType) {
        case 'checkbox':
            if ($(element).is(":checked")) {
                $(element).removeClass('error');
            } else {
                $(element).addClass('error');
            }
            break;
        case 'email':
            if(validateEmail(value)) {
                $(element).removeClass('error');
            } else {
                $(element).addClass('error');
            }
            break;
    }
}

// For showing success modal
function formSuccess(form) {
    
    $('.modal').modal('hide');
    
    if(form === 'support-form') {
        $('.modal.support-success').modal('show');
    } else {
        $('.modal.contact-success').modal('show');
    }
}

// For showing message
function showAlert(targetForm, type, message) {
    
    var successAlert = $('#' + targetForm + ' .js-form-success');
    var failAlert = $('#' + targetForm + ' .js-form-error');
    
    if(type === 'success') {
        successAlert.show();
    } else if(type === 'fail') {
        failAlert.html(message);
        failAlert.show();
    }
}

// For hiding message
function hideAlert(targetForm, type) {
    
    var successAlert = $('#' + targetForm + ' .js-form-success');
    var failAlert = $('#' + targetForm + ' .js-form-error');
    
    if(type === 'success') {
        successAlert.hide();
    } else if(type === 'fail') {
        failAlert.hide();
    }
}

// For clearing all fields
function clearFields(targetForm) {
    
    $('#' + targetForm + ' input, #' + targetForm + ' textarea').each(function(){
        $(this).val('');
    });
}

// For sending actual request
function sendFormData(targetForm, datastr) {
    
    $.ajax({
        type: "POST",
        url: getBaseURL() + "content/themes/waard-tot-klif/lib/send-mail.php",
        data: datastr,
        cache: false,
        success: function(response) {
            if(response === '200') {
                clearFields(targetForm);
                formSuccess(targetForm);
            } else {
                showAlert(targetForm, 'fail', '');
            }
        },
        error: function(xhr, ajaxOptions, thrownError) {
            console.log('Error: ', thrownError);
        }
    });
}

// For sending contact form
function prepareFormData(targetForm) {

    var error = false;
    var datastr = {};
    
    datastr.subject = $('#' + targetForm).attr('data-form-subject');
    
    $('#' + targetForm + ' select').each(function(){
        checkFields(this);
        if($(this).hasClass('error')) {
            error = true;
        } else {
            var key = $(this).attr('name');
            var value = $('option:selected', this).text();
            datastr[key] = value;
        }
    });
    
    $('#' + targetForm + ' input, #' + targetForm + ' textarea, #' + targetForm + ' checkbox').each(function(){
        checkFields(this);
        if($(this).hasClass('error')) {
            error = true;
        } else {
            var key = $(this).attr('name');
            var value = $(this).val();
            datastr[key] = value;
        }
    });

    if(!error) {
        hideAlert(targetForm, 'fail');
        sendFormData(targetForm, datastr);
    } else {
        var message = '<i class="fa fa-exclamation-triangle"></i> Vul alle verplichte velden en probeer het nogmaals';
        showAlert(targetForm, 'fail', message);
        hideAlert(targetForm, 'success');
    }
}
