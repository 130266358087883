/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var $ = jQuery;
(function($) {

    // For triggering image animations
    function initImageAnimations() {

        function showImages() {

            var direction = $(this.element).attr('data-direction');

            if(direction === 'up') {
                $(this.element).velocity('transition.slideUpIn');
            } else if(direction === 'right') {
                $(this.element).velocity('transition.slideRightIn');
            } else {
                $(this.element).velocity('transition.fadeIn');
            }
            this.destroy();
        }

        $('.el-reveal').each(function() {

            new Waypoint({
                element: this,
                handler: showImages,
                offset: '80%',
                group: 'images'
            });
        });
    }

    function toggleContactOSD() {

        const contactOSD = $('.contact-osd');

        if(contactOSD.hasClass('shown')) {
            contactOSD.removeClass('shown');
        } else {
            contactOSD.addClass('shown');
        }
    }

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages

                // Reveal animated elements
                initImageAnimations();

                // Toggle mobile menu
                $('.menu-toggle').click(function() {
                    toggleMobileMenu();
                });

                // Autofocus when search modal is shown
                $('.modal.search').on('shown.bs.modal', function () {
                    $('#searchField').focus();
                });

                // Submit action
                $('.js-form-submit').click(function() {
                    var formId = $(this).attr('data-target-form');
                    prepareFormData(formId);
                });

                // For triggering dropdown menus
                $('.nav-primary .dropdown').mouseenter(function() {
                    $(this).find('.dropdown-menu').show();
                });
                $('.nav-primary .dropdown').mouseleave(function() {
                    $(this).find('.dropdown-menu').hide();
                });

                // For buttons with waypoint trigger
                $('.waypoint-trigger').click(function() {
                    var scrollTarget = $(this).attr('data-target');
                    $('.' + scrollTarget).velocity('scroll', { duration: 500, offset: -83});
                    return false;
                });

                // For toggling the contact osd
                $('.contact-label').click(function() {
                    toggleContactOSD();
                });

                // Init slider
                $('.testimonial-slider').slick({
                    infinite: true,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 5000,
                });
            }
        }
    };
    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';
            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');
            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });
            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };
    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.

