// For showing and hiding mobile menu
function toggleMobileMenu() {
    
    var mobileNav = $('.mobile-menu');
    var navBar = $('.banner');
    var menuItems = $('.mobile-nav.animated .menu-item');
    var body = $('body');
    var iconBarTop = $('.icon-bar.top');
    var iconBarMiddle = $('.icon-bar.middle');
    var iconBarBottom = $('.icon-bar.bottom');
    
    	if(mobileNav.hasClass('visible')) {
        	
        	mobileNav.velocity('transition.slideDownOut', { 
            	duration: 300, 
            	complete: function(){
                	mobileNav.removeClass('visible');
                menuItems.css({ opacity: 0 });
            	} 
        	});
        	
        	if($(window).scrollTop() === 0) {
            	navBar.removeClass('affix');
        	}
        	
        	body.removeClass('overflow-hidden');
        navBar.removeClass('no-shadow');
        iconBarTop.removeClass('top-animate');
        iconBarMiddle.removeClass('middle-animate');
        iconBarBottom.removeClass('bottom-animate');
    	} else {
        	
        	navBar.addClass('affix');
        	mobileNav.velocity('transition.slideUpIn', { 
            	duration: 500, 
            	complete: function(){
                	mobileNav.addClass('visible');
                	menuItems.velocity('transition.slideUpIn', {
                    duration: 300,
                    stagger: 50,
                });
            	} 
        	});
        	
        	body.addClass('overflow-hidden');
        	navBar.addClass('no-shadow');
        iconBarTop.addClass('top-animate');
        iconBarMiddle.addClass('middle-animate');
        iconBarBottom.addClass('bottom-animate');
    	}
}
